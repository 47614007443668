import { Container } from '@material-ui/core';
import { graphql } from 'gatsby';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import '../fonts/fonts.css';
import React from 'react';
import SEO from '../components/SEO';
import Header from '../components/layouts/Header';
import Footer from '../components/layouts/Footer';
import BlogFeedComponent from '../components/blogfeed';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    webPageContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'left',
      marginTop: 15,
    },
    descriptionPlaceholder: {
      textAlign: 'left',
      fontFamily: 'Roboto Mono',
    },
    latestPublicationsFeedContainerTitle: {
      marginTop: 20,
      fontSize: 28,
      fontFamily: 'Roboto Mono',
      color: theme.palette.text.primary,
      textAlign: 'left',
    },
  })
);

export default function HomePage({ data }) {
  const classes = useStyles();
  const title = data.site.siteMetadata.title;
  const description = data.site.siteMetadata.description;

  while (data.allMarkdownRemark.edges.length > 5) {
    data.allMarkdownRemark.edges.pop();
  }

  return (
    <div className={classes.root}>
      <Header title={title} />
      <SEO title={title} description={description} />
      <BlogFeedComponent data={data} />
      <Footer />
    </div>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        description
        title
      }
    }
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
            author
            link
            featuredImage {
              childImageSharp {
                fluid(base64Width: 200) {
                  base64
                }
                fixed(width: 100, trim: 1.5, height: 100) {
                  base64
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`;
