import { Grid, Typography } from '@material-ui/core';
import { Link } from 'gatsby';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Img from 'gatsby-image';
import React from 'react';
import '../fonts/fonts.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    latestPublicationsFeedContainer: {
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'Roboto Mono',
      padding: 20,
    },
    postObject: {
      marginBottom: 20,
      fontFamily: 'Roboto Mono',
      textAlign: 'left',
    },
    postObjectTitle: {
      fontWeight: 200,
      color: theme.palette.common.white,
      fontFamily: 'Roboto Mono',
      textAlign: 'left',
      textDecoration: 'none',
      marginLeft: 20,
      '&:hover': {
        color: theme.palette.primary.light,
        fontWeight: 400,
      },
    },
    postObjectImage: {
      height: 50,
      width: 50,
    },
    postObjectImageStretcher: {
      borderRadius: 10,
      border: theme.palette.common.white,
      borderWidth: 1,
      height: 50,
      width: 50,
      '&:hover': {
        border: theme.palette.primary.light,
        borderWidth: 5,
      },
    },
  })
);

export default function BlogFeedComponent({ data }) {
  const classes = useStyles();
  while (data.allMarkdownRemark.edges.length > 5) {
    data.allMarkdownRemark.edges.pop();
  }

  return (
    <Grid className={classes.latestPublicationsFeedContainer}>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <Grid container key={node.id} className={classes.postObject}>
          {node.frontmatter.featuredImage && (
            <Grid item className={classes.postObjectImage}>
              <Link to={node.fields.slug}>
                <Img
                  fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                  // fixed={node.frontmatter.featuredImage.childImageSharp.fixed}
                  className={classes.postObjectImageStretcher}
                />
              </Link>
            </Grid>
          )}
          <Grid item>
            <Typography
              variant="h6"
              component={Link}
              to={node.fields.slug}
              className={classes.postObjectTitle}
            >
              {`> ${node.frontmatter.title}`}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
